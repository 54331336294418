/**
 * @file Analytics methods for google analytics and rudderstack
 */
const trackEvent = function (category, action, label, value, eventProps) {
  if (window.rudderanalytics && window.$pepinTraits) {
    const rudderEvent = 'user_action'
    const rudderProperties = { category, action, label, value, eventProps }
    const rudderOptions = { traits: window.$pepinTraits }
    window.rudderanalytics.track(rudderEvent, rudderProperties, rudderOptions)
  }
}
// Function to track pageview for javascript pages
const trackPageview = function (category, name) {
  if (window.rudderanalytics && window.$pepinTraits) {
    const rudderOptions = { traits: window.$pepinTraits }
    window.rudderanalytics.page(category, name, undefined, rudderOptions)
  }
}
// Function to track engagement time in a wall
const trackWallEngagementTime = function () {
  if (window.rudderanalytics && window.$pepinTraits) {
    const rudderOptions = { traits: window.$pepinTraits }
    rudderOptions.traits.pingIntervalInMilliseconds = 15_000
    window.rudderanalytics.track('wall_engagement_time_ping', {}, rudderOptions)
  }
}

export { trackEvent, trackPageview, trackWallEngagementTime }
